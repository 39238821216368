<template>
  <div>
    <!-- <Loader v-if="is_loading" style="display: flex; align-items: center; justify-content: center;width:100;height:100%;" v-bind:logo="loaderLogo" ></Loader> -->
    <Loader
      v-if="is_loading"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 300px;
      "
      v-bind:logo="loaderLogo"
    ></Loader>
    <b-row class="mt-5" v-else>
      <b-col  md="6" lg="6" class="p-4">
        <b-card style="height:100%">
          <div class="chart-wrap">

            <div class="chart-wrap">
              <div class="chart-title">{{customerDetails.customerTotal}}<br />Total</div>
              <div class="d-lg-flex " style="text-align: start;">
                <div id="chart">
                  <apexchart type="donut" width="250" :options="chartOptions1" :series="customerDetails.customer"></apexchart>
                </div>
                <div class="pt-lg-0 pt-10">
                  <h3 class="m-0" style="font-weight: bolder;">Customers</h3>
                  <!-- <div style="font-size:12px;color:#8F95B2">Customers that buy our products</div> -->
                  <div class="pt-5 d-flex">
                    <div class="d-flex justify-content-center align-items-center pr-5">
                      <div style="background-color:#FF6633" class="round">
                      </div>
                    </div>
                    <div>
                      <div style="font-weight: bolder;">
                      {{ customerDetails.customer ? customerDetails.customer.length > 0  ? customerDetails.customer[0] : '' : '' }}%
                        <!-- ({{customerDetails.newCustomer == 'NaN' ? 0 : customerDetails.newCustomer }}%) -->
                      </div>
                      <div style="font-size:12px;color:#8F95B2">
                        New Customers
                      </div>
                    </div>
                  </div>
                  <div class="pt-5 d-flex">
                    <div class="d-flex justify-content-center align-items-center pr-5">
                      <div style="background-color:#FFE2D9" class="round">
                      </div>
                    </div>
                    <div>
                      <div style="font-weight: bolder;">
                        {{ customerDetails.customer ? customerDetails.customer.length > 0  ? customerDetails.customer[1] : '' : ''  }} %
                        <!-- ({{customerDetails.oldCustomer == 'NaN' ? 0 : customerDetails.oldCustomer}}%) -->
                      </div>
                      <div style="font-size:12px;color:#8F95B2">
                        Existing Customers
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="6" lg="6" class="p-4">
        <b-card>
          <div class="chart-wrap">

            <div class="chart-wrap">
              <div class="chart-title1" >{{countryDetails.total}}<br />Total</div>
              <div class="d-lg-flex" style="text-align: start;">
                <div id="chart">
                  <apexchart type="donut" width="250" :options="chartOptions2" :series="series2"></apexchart>
                </div>
                <div class="pt-lg-0 pt-10">
                  <h3 class="m-0" style="font-weight: bolder;">Country</h3>
                  <!-- <div style="font-size:12px;color:#8F95B2">Customers that buy our products</div> -->
                  <b-row v-if="countryDetails.value" class="mt-2 scrollBar" style="max-height: 215px; overflow-y: auto;">
                    <template v-for="(item,index) in countryDetails.value.slice(0,sliceValue)">
                    <b-col md="6" lg="6" :id="'popover-target-'+index">
                    <div class="d-flex" >
                      <div class="d-flex justify-content-center align-items-center pr-5">
                        <div :style="'background-color:'+item.color" class="round">
                        </div>
                      </div>
                      <div>
                        <div style="font-weight: bolder;">
                          {{(item.value)}}%
                        </div>
                        <div style="font-size:12px;color:#8F95B2">
                          {{item.label}}
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-popover v-if="countryDetails.value ? index == countryDetails.value.length -1 : '' && item.label ==  'Others'"  :target="'popover-target-' + index"
                    triggers="hover"
                    variant="primary"
                    placement="bottom" >
                      Countries that have orders less than 0.01%
                    </b-popover>
                </template>
                  <b-col v-if="countryDetails.value ? sliceValue < countryDetails.value.length : '' && countryDetails.value.length > 4" md="12">
                    <button class="btn btn-success btn-sm" @click="sliceValue = countryDetails.value.length">View More</button>
                  </b-col>
                  <b-col v-else-if="countryDetails.value ? countryDetails.value.length == sliceValue : '' && countryDetails.value.length > 4" md="12">
                    <button class="btn btn-info btn-sm" @click="sliceValue = 4">View Less</button>
                  </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <template>
      <b-overlay   v-if="monthlyOverlay"
                style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 300px;
      "
      ></b-overlay >
      <b-col md="12" lg="12" v-else class="p-4">
        <b-card  v-if="series.length > 0" >
          <div id="app">
            <h2 style="font-weight: bolder;">Monthly Performance</h2>
            <div id="chart">
                <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>
          </div>
        </b-card>
      </b-col>
    </template>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"; // Import the VueApexCharts component
import ApiService from "../../core/services/api.service";
import Loader from "@/view/content/TabLoader.vue";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  components: {
    Loader,
    apexchart: VueApexCharts,
  },
  data() {
    return {
        sliceValue:4,
      is_loading : false,
      monthlyOverlay: false,
      series: [
        {
          name: "Number of Orders",
          data: [],
        },
        {
          name: "Payout Received",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",

        zoom: {
          enabled: false, // Enable zoom functionality
          // type: 'x', // Set zoom type (x, y, xy)
          // autoScaleYaxis: false, // Disable auto-scaling of the y-axis
          // zoomedArea: {
          //   fill: {
          //     color: '#fff', // Set the color for the zoomed area
          //     opacity: 0.3, // Set the opacity for the zoomed area
          //   },
          // },
        },
          toolbar: {
            show: false, // Show the toolbar with reset and other buttons
            // autoSelected: 'zoom', // Initially select the 'zoom' button
            // tools: {
            //   download: false, // Disable the download button
            //   selection: false, // Disable the selection tool button
            //   zoomin: false, // Enable the zoom-in button
            //   zoomout: false, // Enable the zoom-out button
            //   pan: false, // Disable the pan button
            //   reset: false, // Enable the reset button
            // },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category", // Use category type for fixed labels
          categories: ['1'],
        },
        legend: {
          position: 'top', // Set the legend position to the top
          horizontalAlign: 'right',
        },
        yaxis: {
      // tickAmount: 4,
          // min: 0, // Set the minimum value for the y-axis
          // max: 20000, // Set the maximum value for the y-axis
          labels: {
            formatter: function (value) {
              if (value >= 5000) {
                // Convert to 'k' format
                return (value / 1000).toFixed(0) + 'k';
              } else {
                return value.toFixed(2).toString();
              }// Customize y-axis label format
            },
          },
        },
        tooltip: {
          theme: "dark",
        },
        colors: ["#9966CC", "#FF6633"], // Change the colors of the series
        markers: {
          colors: ["#9966CC", "#FF6633"], // Change the colors of the markers
        },

      },
      chartOptions1: {
        labels: ['New Customers', 'Existing Customers'],
        chart: {
          width: 250,
          type: 'donut',
        },
        colors: ["#FF6633", "#FFE2D9"], // Change the colors of the series
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0, // Set this to control the gap
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        }
      },
      countryDetails:{
        value:[],
        total:0
      },
      customerDetails:[],
      series2: [],
      chartOptions2: {
        labels: [],
        chart: {
          width: 250,
          type: 'donut'
        },
        colors:[],// Change the colors of the series
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0, // Set this to control the gap
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        }
      }
    };
  },
  props:['filterApplied','filterData','selected_tab'],
  watch: {
    filterData :{
      deep: true,
      handler(){
        if(this.selected_tab == 'dashboard'){
          this.getData();
          this.getMonthlyData();
        }
      }
    },

    selected_tab(newValue) {
        if(this.selected_tab == 'dashboard'){
          this.getData();
          this.getMonthlyData();
        }
    }
  },
  computed:{
    ...mapGetters(["layoutConfig"]),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
  created(){
    this.getData();
    this.getMonthlyData();
  },
  methods: {
    abortFetch() {

      if (this.abortController) {
        this.abortController.abort();
      }
    },
    async getData(){
      this.is_loading = true;

      let data ={
        affiliate_id: window.localStorage.getItem("affiliate_id"),
      }

      // if(this.filterApplied){
        data={...data,
        advertiser_id: this.filterData?.advertiser_id ? this.filterData.advertiser_id.map(i => {return i.advertiser_id}) : [],
        coupon_code:this.filterData?.coupon_code,
        coupon_status:this.filterData?.coupon_status,
        timeperiod:this.filterData?.date_type,
        startdate: moment(this.filterData?.start_date).format('YYYY-MM-DD') ,
        enddate: moment(this.filterData?.end_date).format('YYYY-MM-DD')
      }

      // }
      await ApiService.post("/overview",data,this.abortController).then((response) => {
        let total_orders = response.data.data.order_detail_view.orders;
        let total_payouts = Number(response.data.data.payout.payout).toFixed(0);
        this.$emit('update-parent-variable',total_orders);
        this.$emit('update-payout-variable',total_payouts);

        console.log(response.data.data.order_detail_view.orders);
        window.localStorage.setItem("total_orders",response.data.data.order_detail_view.orders)
        console.log(Number(response.data.data.payout.payout).toFixed(0));
        // this.dashboard.total_conversions = response.data.data.order_detail_view.orders;
        // this.dashboard.total_payout = Number(response.data.data.payout.payout).toFixed(0);
        let customer = response.data.data.customer.data
         this.customerDetails={
            customer: [parseFloat(customer.total_new.toFixed(2)), parseFloat(parseFloat(customer.total_old + customer.total_both).toFixed(2))],
            // customerTotal: (parseFloat(customer.total_new.toFixed(2)) + parseFloat(customer.total_old.toFixed(2))).toFixed(2),
            customerTotal: response.data.data.customer.total_orders,
            newCustomer: parseFloat(parseFloat(customer.total_new)*100/(parseFloat(customer.total_new) + parseFloat(customer.total_old))).toFixed(2),
            oldCustomer: parseFloat(parseFloat(customer.total_old)*100/(parseFloat(customer.total_new) + parseFloat(customer.total_old))).toFixed(2),
         }
        let country = response.data.data.country.data
        let arr = []
        this.countryDetails = {
            total: 0,
            value:[]
        }

        let Sum = 0
         Object.keys(country).map(item => {
          if(country[item] >= 0.01){
            if(item !== 'Others'){
          const colorArray = ["#008000","#FFFF00","#808080","#800080","#008080","#00FFFF","#800000","#FF00FF","#C0C0C0","#FFA500","#FF4500","#ADFF2F","#FFD700","#FF69B4","#8A2BE2","#00FF00","#7FFF00","#20B2AA","#87CEEB","#7B68EE","#4169E1","#8B008B","#8B0000","#556B2F","#2E8B57","#9400D3","#B22222","#32CD32","#48D1CC","#4B0082","#9932CC","#8B4513","#DEB887","#5F9EA0","#D2691E","#DC143C","#8B4513","#A0522D","#2E8B57","#008B8B","#9932CC","#8A2BE2","#800000","#A0522D","#4682B4","#800080","#8A2BE2","#D2691E","#228B22","#2E8B57","#6A5ACD","#D2B48C"]
          // Generate a random index based on the array length
          const randomIndex = Math.floor(Math.random() * colorArray.length);
					let bgColor =  colorArray[randomIndex]
          let obj = {
            label:item,
            value:parseFloat(country[item].toFixed(2)),
            color:bgColor
          }
          arr.push(obj)
          //  this.countryDetails.total = (this.countryDetails.total + country[item])
           this.countryDetails.total = response.data.data.country.total_orders
        }else{
          Sum += parseFloat(country[item])
        }
        }else{
          Sum += parseFloat(country[item])
        }
         })
         let obj = {
            label:'Others',
            value:parseFloat(Sum.toFixed(2)),
            color:'#ff6633'
          }
          if(obj.value > 0){
            arr.push(obj)
          }
      this.countryDetails.total = response.data.data.country.total_orders
      this.countryDetails.value = arr;
       this.series2 =  this.countryDetails.value.map(item1 => { return item1.value}),
       this.chartOptions2.labels =  this.countryDetails.value.map(item1 => { return item1.label}),
        this.chartOptions2.colors =  this.countryDetails.value.map(item1 => { return item1.color})// Change the colors of the series

        this.is_loading = false;
        // this.$forceUpdate();

      })
      .catch(()=>{
        this.is_loading = false

         })

    },
     getMonthlyData(){
      this.monthlyOverlay = true;


      let data ={
        affiliate_id: window.localStorage.getItem("affiliate_id"),
      }

      // if(this.filterApplied){
      data={...data,
        advertiser_id: this.filterData?.advertiser_id ? this.filterData.advertiser_id.map(i => {return i.advertiser_id}) : [],
        coupon_code:this.filterData?.coupon_code,
        coupon_status:this.filterData?.coupon_status,
        timeperiod:this.filterData?.date_type,
        startdate: moment(this.filterData?.start_date).format('YYYY-MM-DD') ,
        enddate: moment(this.filterData?.end_date).format('YYYY-MM-DD')
      }

      // }
       ApiService.post("/month_overview",data,this.abortController).then((response) => {
        let order= response.data.data.order_detail_view.data
        let payout= response.data.data.payout.data
        let sequenceData = this.sortDates(payout).map(item => { return item})
        this.chartOptions.xaxis.categories = sequenceData.map(item => { return item.label})
        let seriesorder = []
        let seriespayout = []
        sequenceData.map(item1 => {
          order.map(item => {
            let month = item.month
            month = new Date(month)
            const year = month.getFullYear();
            const month1 = (month.getMonth() + 1).toString().padStart(2, '0');
            month = `${year}-${month1}`;
            if(item1.value == month){
              seriesorder.push(item)
            }
          })
          payout.map(item => {
            let month = item.month
            month = new Date(month + '-01')
            const year = month.getFullYear();
            const month1 = (month.getMonth() + 1).toString().padStart(2, '0');
            month = `${year}-${month1}`;
            if(item1.value == month){
              seriespayout.push(item)
            }
          })
        })
        this.series[0].data = seriesorder.map(item => {return parseFloat(item.order)})
        this.series[1].data = seriespayout.map(item => {return item.payout})
        // this.$forceUpdate();
        this.monthlyOverlay = false;


      })
              .catch(()=>{

              })

    },
    sortDates(data){
      let dateArray = data
      const dateObjects = dateArray.map(dateStr =>new Date(dateStr.month));
      dateObjects.sort((a, b) => a - b);
        const sortedDateArray = dateObjects.map(date => {
        const month1 = moment(date).format('MMMM').slice(0,3)
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        let obj = { value : `${year}-${month}`, label: `${month1}-${year.toString().slice(2,4)}`}
        return obj
      });

      return sortedDateArray
    }

  }
};
</script>

<style scoped>
.chart-wrap {
  position: relative;
  text-align: center;
}

.chart-title, .chart-title1 {
  position: absolute;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
@media screen and (min-width: 480px) {
  .chart-title{
  top: 100px;
  left:125px;
  font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .chart-title{
  top: 74px;
  left:103px;
  font-size: 15px;
  }
}

@media screen and (min-width: 480px) {
  .chart-title1{
  top: 90px;
  left:125px;
  font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .chart-title1{
  top: 74px;
  left:103px;
  font-size: 15px;
  }
}
.round {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.scrollBar::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners of the thumb */
}

.scrollBar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

.card {
  box-shadow: 0px 0px 15px #b9adba8c
}

/* Other styles for your component */</style>
